import * as schemaItemTypes from './schemaItemTypes';

/** @type {import('./schema').default}  */
export const schema = [
  {
    type: schemaItemTypes.CLIENT_TYPE,
    label: 'label.client_type',
    prop: 'clientType',
    clearable: true,
  },
  {
    type: schemaItemTypes.MANY_BUILDINGS,
    label: 'label.buildings',
    prop: 'buildings',
    returnArrayIncludes: true,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'label.contract_period',
    prop: 'rentalPeriod',
    clearable: true,
    multiple: true,
  },
  {
    type: schemaItemTypes.AGREEMENT_STATUS_SELECT,
    label: 'label.contractor_status',
    prop: 'contractorStatus',
    clearable: true,
  },
  {
    type: schemaItemTypes.AGREEMENT_STATUS_SELECT,
    label: 'label.client_status',
    prop: 'clientStatus',
    clearable: true,
  },
];

export default schema;
