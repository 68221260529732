<template>
  <base-missing-data class="templates-missing-data" :icon="$options.IconMissingDataContracts" :message="message" />
</template>

<script>
// Components
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataContracts from '@/components/MissingDataIcons/MissingDataContracts.vue';

export default {
  name: 'ContractsMissingData',

  components: { BaseMissingData },

  props: {
    message: {
      type: String,
      default: 'templates.missing_message',
    },
  },

  IconMissingDataContracts,
};
</script>
