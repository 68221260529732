<template>
  <div style="height: 100%">
    <entity-status-list :statuses="entityStatuses">
      <template v-if="canDisplayActions">
        <v-btn tag="label" color="primary" large class="me-4" for="newTemplateDocument" style="cursor:pointer;">
          {{ $t('button.new_template') }}
          <v-icon>mdi-plus</v-icon>
          <form ref="form" style="display:none;">
            <input
              id="newTemplateDocument"
              ref="inputUploadFile"
              name="file"
              type="file"
              :accept="acceptFileTypes"
              @change="createNewTemplate($event.target)"
            />
          </form>
        </v-btn>
      </template>
    </entity-status-list>

    <templates v-if="isTemplatesPage" />
    <agreements v-if="isAgreementsPage" />
  </div>
</template>

<script>
// Constants
import { TEMPLATES, TEMPLATES_EDIT, AGREEMENTS } from '@/constants/routes';
import { CONTRACTS as CONTRACTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

// Services
import notificationService from '@/services/notification';
import contractService from '@/services/contract';

// Components
import EntityStatusList from '@/components/EntityStatusList.vue';
import Templates from './Templates.vue';
import Agreements from './Agreements.vue';

export default {
  name: 'Contracts',

  components: {
    EntityStatusList,
    Templates,
    Agreements,
  },

  inject: ['media'],

  data() {
    return {
      acceptFileTypes: 'image/*, application/pdf',
    };
  },

  computed: {
    entityStatuses() {
      return [
        { id: 0, text: this.$t('contracts.templates'), name: TEMPLATES },
        { id: 1, text: this.$t('contracts.e_signed'), name: AGREEMENTS },
      ];
    },

    isTemplatesPage() {
      return this.$route.name === TEMPLATES;
    },

    isAgreementsPage() {
      return this.$route.name === AGREEMENTS;
    },

    canDisplayActions() {
      return !this.media.isMobile && this.canUpdateContracts;
    },

    canUpdateContracts() {
      return this.$can(UPDATE, CONTRACTS_SUBJECT);
    },
  },

  methods: {
    async createNewTemplate(input) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('document', file);

      this.$options.notificationItem = notificationService.info(
        this.$t('contracts.file_is_uploading'),
        null,
        350,
        true
      );

      contractService
        .createTemplate(formData)
        .then(({ data }) => {
          this.$router.push({ name: TEMPLATES_EDIT, params: { id: data.id } });
        })
        .finally(() => {
          notificationService.remove(this.$options.notificationItem);
          this.$options.notificationItem = null;
        });
    },
  },

  notificationItem: null,
};
</script>
