/* eslint-disable */
import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/contracts';

class ContractService {

  async getAgreements(params, payload) {
    return client.post(`${BASE_URL}/agreement/`, payload, { params });
  }

  async getTemplates() {
    return client.get(`${BASE_URL}/template/`);
  }

  async createTemplate(formData) {
    return client.put(`${BASE_URL}/template/`, formData);
  }

  async getDocumentById(id) {
    return client.get(`${BASE_URL}/template/${id}/`);
  }

  async getTemplateById(id) {
    return client.get(`${BASE_URL}/template/${id}/docuseal/`);
  }

  async saveTemplate(template, id) {
    return client.put(`${BASE_URL}/template/${id}/docuseal/`, { template });
  }

  async sendTemplate (data) {
    return client.post(`${BASE_URL}/agreement/send/`, data);
  }

  async cloneTemplate(id) {
    return client.put(`${BASE_URL}/template/${id}/`);
  }

  async deleteAgreement(id) {
    return client.delete(`${BASE_URL}/agreement/${id}/`);
  }

  async getAgreement(id) {
    return client.get(`${BASE_URL}/agreement/${id}/`);
  }

  async exportAgreement(id) {
    return client.getRaw(`${BASE_URL}/agreement/${id}/download/`, { responseType: 'blob' });
  }

  async downloadAgreement(id, fallbackFilename) {
    const response = await this.exportAgreement(id);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  async deleteTemplate(id) {
    return client.delete(`${BASE_URL}/template/${id}/`);
  }

  async resendEmail(role, agreemntId) {
    return client.post(`${BASE_URL}/agreement/${agreemntId}/send_notification_mail/`, { role });
  }
}

export default new ContractService();
