<template>
  <div class="agreements-mobile-list mt-6">
    <div v-for="agreement in agreements" :key="agreement.id" class="mb-4">
      <filled-mobile-list hide-header>
        <div class="d-flex align-center justify-space-between">
          <view-list-field :label="$t('contracts.contract_name')" text-font-weight="font-weight-bold">
            <span @click="$emit('show-agreement', agreement)">{{ agreement.contractName }}</span>
          </view-list-field>
          <icon-button exact :width="36" class="ml-2" @click.stop @click="$emit('delete', agreement)">
            <v-icon color="primary">mdi-delete</v-icon>
          </icon-button>
        </div>
        <div class="d-flex align-center justify-space-between mt-3">
          <view-list-field :label="$t('contracts.client')">
            {{ agreement.clientName }}
            <span style="opacity: 0.5">{{ agreement.clientType }}</span>
          </view-list-field>
          <v-chip
            :color="statusColors[agreement.clientStatus]"
            :text-color="statusColors[agreement.clientStatus] ? 'white' : ''"
          >
            {{ $t(`status.${agreement.clientStatus}`) }}
          </v-chip>
        </div>
        <div class="d-flex align-center justify-space-between mt-3">
          <view-list-field label="Contractor">
            {{ agreement.contractorEmail }}
          </view-list-field>
          <v-chip
            :color="statusColors[agreement.contractorStatus]"
            :text-color="statusColors[agreement.contractorStatus] ? 'white' : ''"
          >
            {{ $t(`status.${agreement.contractorStatus}`) }}
          </v-chip>
        </div>
        <v-row class="d-flex mt-3">
          <v-col>
            <view-list-field :label="$t('label.building')" class="me-5">
              {{ agreement.buildingName }}
            </view-list-field>
          </v-col>
          <v-col>
            <view-list-field :label="$t('label.unit')" class="me-6">
              {{ agreement.unitName }}
            </view-list-field>
          </v-col>
          <v-col v-if="accessToRooms">
            <view-list-field :label="$t('label.room')">
              {{ agreement.roomName }}
            </view-list-field>
          </v-col>
        </v-row>
        <div class="mt-3">
          <view-list-field :label="$t('contracts.contract_period')" class="me-5">
            {{ agreement.contractPeriod }}
          </view-list-field>
        </div>
      </filled-mobile-list>
    </div>
  </div>
</template>

<script>
import FilledMobileList from '@/components/FilledMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'TasksMobileList',
  components: { FilledMobileList, ViewListField, IconButton },
  props: {
    agreements: { type: Array, required: true },
    canUpdate: { type: Boolean, default: false },
    statusColors: { type: Object, required: true },
    accessToRooms: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.agreements-mobile-list {
  &__container-line {
    height: 34px;
    width: calc(100% + 16px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__line {
    border-bottom: 1px solid #fff;
    width: 100%;
    position: absolute;
    right: 0;
    left: -8px;
  }
  &__title {
    font-weight: $--font-weight-medium;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
</style>
