<template>
  <div class="filled-mobile-list">
    <v-row v-if="!hideHeader" no-gutters class="filled-mobile-list__header">
      <slot name="header">
        <v-col class="py-3 px-2" cols="6">
          <view-list-field :label="$t('invoice.date')">
            {{ date }}
          </view-list-field>
        </v-col>
        <v-col class="py-3 px-2" cols="6">
          <view-list-field :label="$t('invoice.period')">
            {{ period }}
          </view-list-field>
        </v-col>
      </slot>
    </v-row>

    <div class="filled-mobile-list__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// Components
import ViewListField from '@/components/ViewListField.vue';

export default {
  name: 'FilledMobileList',

  components: { ViewListField },

  props: {
    hideHeader: { type: Boolean, default: false },
    date: { type: String, default: '' },
    period: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.filled-mobile-list {
  position: relative;
  background: rgba($--blue-color-65, 0.8);
  border-radius: 4px;
  overflow: hidden;

  &__header {
    background-color: $--blue-color-55;

    .col:first-child {
      border-right: 1px solid $--blue-color-65;
    }
  }

  &__content {
    padding: 19px 8px 16px 8px;
  }
}
</style>
