<template>
  <div class="agreements" style="height: 100%">
    <base-dialog ref="deleteDialog" :title="$t('agreement.deletion_confirmation')" />
    <div v-if="isLoading" class="d-flex align-center justify-center" style="height: 100%">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <templates-missing-data v-else-if="isDataMissing" :message="$t('agreements.missing_message')" />

    <template v-else>
      <template v-if="media.isMobile">
        <mobile-sort
          class="mt-2 mb-0 mb-md-6"
          :sort-list="sortedAgreementsHeaders"
          :sort-by="activeHeader"
          :options.sync="options"
          :reverse="media.isMobile"
        >
          <icon-button
            tag="label"
            color="primary"
            class="mt-5"
            for="newTemplateDocument"
            :width="44"
            style="cursor:pointer;"
          >
            <v-icon>mdi-plus</v-icon>
            <form ref="form" style="display:none;">
              <input
                id="newTemplateDocument"
                ref="inputUploadFile"
                name="file"
                type="file"
                :accept="acceptFileTypes"
                @change="createNewTemplate($event.target)"
              />
            </form>
          </icon-button>
        </mobile-sort>

        <div class="d-flex align-center mb-2">
          <mobile-search-menu v-model="query" :placeholder="searchPlaceholder" single-line full-width hide-details />

          <agreements-modal-filters v-model="filtersData" />
        </div>

        <agreements-mobile-list
          :agreements="agreements"
          :status-colors="statusColors"
          :access-to-rooms="accessToRooms"
          @show-agreement="showAgreement"
          @delete="deleteAgreement"
        />
      </template>
      <div v-else class="agreements">
        <search-bar
          v-model="query"
          class="pt-3 mt-0"
          :class="{ 'mr-4': false }"
          :placeholder="searchPlaceholder"
          :options.sync="options"
          full-width
          hide-details
        />

        <agreements-filter v-model="filtersData" />
        <template v-if="!isLoading">
          <base-table
            :headers="accessToRooms ? agreementHeadersWithRooms : agreementHeaders"
            :loading="tableLoading"
            :items="agreements"
            hide-default-footer
            @click:row="showAgreement"
          >
            <template #item.contractorStatus="{ item }">
              <v-chip
                :color="statusColors[item.contractorStatus]"
                :text-color="statusColors[item.contractorStatus] ? 'white' : ''"
              >
                {{ $t(`status.${item.contractorStatus}`) }}
              </v-chip>
            </template>
            <template #item.clientStatus="{ item }">
              <v-chip :color="statusColors[item.clientStatus]" :text-color="item.clientStatus ? 'white' : ''">
                {{ $t(`status.${item.clientStatus}`) }}
              </v-chip>
            </template>
            <template #item.actions="{ item }">
              <icon-button exact :width="36" :disable="tableLoading" @click.stop="deleteAgreement(item)">
                <v-icon color="primary">mdi-delete</v-icon>
              </icon-button>
            </template>
          </base-table>
        </template>
      </div>

      <base-pagination v-if="pageCount" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// node_modules
import format from 'date-fns/format';

// Components
import SearchBar from '@/components/SearchBar.vue';
import IconButton from '@/components/IconButton.vue';
import AgreementsFilter from '@/components/Agreements/Filters.vue';
import BaseTable from '@/components/BaseTable.vue';
import AgreementsMobileList from '@/components/Agreements/MobileList.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import TemplatesMissingData from '@/components/Contracts/TemplatesMissingData.vue';

// Services
import contractService from '@/services/contract';

// Utils
import { debounce } from '@/utils/delay';
import { clone } from '@/utils/clone';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { isEqual } from '@/utils/common';
import { camelToSnake } from '@/utils/formatters';

// Schemas
import { createModelData } from '@/schemas/createModelData';
import { schema } from '@/schemas/agreementFilter.schema';

import { AGREEMENTS_DETAILED } from '@/constants/routes';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

import { mapGetters } from 'vuex';

export default {
  name: 'Agreements',
  components: {
    BaseDialog,
    SearchBar,
    IconButton,
    AgreementsFilter,
    BaseTable,
    AgreementsMobileList,
    MobileSearchMenu,
    MobileSort,
    BasePagination,
    TemplatesMissingData,
    AgreementsModalFilters: () => import('@/components/Agreements/ModalFilters.vue'),
  },
  inject: ['media'],
  props: {
    createNewTemplate: { type: Function, default: () => {} },
    acceptFileTypes: { type: String, default: '' },
  },
  data() {
    return {
      isDataMissing: false,
      page: +this.$route.query.page || 1,
      pageCount: 1,
      agreementsLimit: 10,
      isLoading: false,
      tableLoading: false,
      searchPlaceholder: this.$t('label.search'),
      query: '',
      filtersData: createModelData(schema),
      options: { sortBy: '', sortDesc: null },
      agreements: [],
      agreementHeadersWithRooms: [
        {
          text: this.$t('contracts.contract_name'),
          value: 'contractName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: true,
        },
        {
          text: this.$t('contracts.client'),
          value: 'clientName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('contracts.client_type'),
          value: 'clientType',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.building'),
          value: 'buildingName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.unit'),
          value: 'unitName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.room'),
          value: 'roomName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.contract_period'),
          value: 'contractPeriod',
          width: '15%',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.contractor'),
          value: 'contractorEmail',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.contractor_status'),
          value: 'contractorStatus',
          width: '160px',
          sortable: false,
        },
        {
          text: this.$t('contracts.client_status'),
          value: 'clientStatus',
          width: '160px',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          width: '48px',
          cellClass: 'px-0',
          sortable: false,
        },
      ],
      agreementHeaders: [
        {
          text: this.$t('contracts.contract_name'),
          value: 'contractName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: true,
        },
        {
          text: this.$t('contracts.client'),
          value: 'clientName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('contracts.client_type'),
          value: 'clientType',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.building'),
          value: 'buildingName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.unit'),
          value: 'unitName',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.contract_period'),
          value: 'contractPeriod',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$t('contracts.contractor'),
          value: 'contractorEmail',
          class: 'text-truncate',
          cellClass: 'text-truncate',
          sortable: false,
        },
        {
          text: this.$t('contracts.contractor_status'),
          value: 'contractorStatus',
          width: '160px',
          sortable: false,
        },
        {
          text: this.$t('contracts.client_status'),
          value: 'clientStatus',
          width: '160px',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          width: '48px',
          cellClass: 'px-0',
          sortable: false,
        },
      ],
      filters: {
        clientType: undefined,
        buildings: undefined,
        rentalPeriodBefore: undefined,
        rentalPeriodAfter: undefined,
        contractorStatus: undefined,
        clientStatus: undefined,
      },
      statusColors: {
        sent: 'primary',
        opened: 'orange',
        completed: 'green',
      },
    };
  },
  computed: {
    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),
    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
    schema() {
      return schema;
    },
    normalizedFilters() {
      return {
        ...this.filters,
        clientType: this.filtersData?.clientType?.value || undefined,
        buildings: checkEmptyParams(this.filtersData?.buildings)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filtersData?.buildings),
        contractorStatus: this.filtersData.contractorStatus?.value || undefined,
        clientStatus: this.filtersData.clientStatus?.value || undefined,
      };
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.agreementHeaders.find(agreementHeader => agreementHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
    sortedAgreementsHeaders() {
      return this.agreementHeaders.slice().sort((a, b) => {
        const order1 = a.order || Number.MAX_VALUE;
        const order2 = b.order || Number.MAX_VALUE;

        return order1 - order2;
      });
    },
    activeHeader() {
      return this.agreementHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },
  },
  watch: {
    query() {
      this.debouceFetch();
    },
    normalizedFilters: {
      handler(newValue, oldValue) {
        if (isEqual(newValue, oldValue)) {
          return;
        }
        this.getAgreements();
        this.isDataMissing = false;
      },
      deep: true,
    },
    filtersData: {
      handler(newValue) {
        this.updateFilters(newValue);
      },
      deep: true,
    },
    async orderBy() {
      this.getAgreements();
    },
    // eslint-disable-next-line
    '$route.query.page': function(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.page = +newValue || 1;
      this.getAgreements();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getAgreements();
    this.isDataMissing = !this.agreements.length && !this.query;
  },
  methods: {
    // eslint-disable-next-line func-names
    debouceFetch: debounce(function() {
      this.getAgreements();
    }, 300),

    async getAgreements() {
      this.tableLoading = true;

      const params = {
        search: this.query,
        limit: this.agreementsLimit,
        offset: this.agreementsLimit * (this.page - 1),
        clientType: this.normalizedFilters.clientType,
        clientStatus: this.normalizedFilters.clientStatus,
        contractorStatus: this.normalizedFilters.contractorStatus,
        rentalPeriodBefore: this.normalizedFilters.rentalPeriodBefore,
        rentalPeriodAfter: this.normalizedFilters.rentalPeriodAfter,
      };

      try {
        const data = await contractService.getAgreements(params, { buildings: this.normalizedFilters.buildings });

        const { count, results } = data;

        this.agreements = this.formatAgreements(results);

        this.pageCount = Math.ceil(count / this.agreementsLimit);
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    },

    formatAgreements(agreements) {
      return agreements.map(agreement => ({
        ...agreement,
        contractName: agreement.document?.name,
        clientName: `${agreement.client?.firstName} ${agreement.client?.lastName}`,
        clientType: this.$t(`client.${agreement.usage?.clientType}`),
        buildingName: agreement.building?.name,
        unitName: agreement.unit?.name,
        roomName: agreement.room?.name,
        contractPeriod:
          agreement.usage.clientType === 'owner'
            ? this.formatDate(agreement.usage.salesContractDate)
            : `${this.formatDate(agreement.usage.rentalPeriodStartDate)} - ${this.formatDate(
                agreement.usage.rentalPeriodEndDate
              )}`,
      }));
    },

    formatDate(date) {
      if (!date) return '';
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'dd MMM yyyy');
    },

    async deleteAgreement(agreement) {
      const needDelete = await this.$refs.deleteDialog.open();

      if (!needDelete) return;

      this.tableLoading = true;

      contractService
        .deleteAgreement(agreement.id)
        .then(() => {
          this.getAgreements();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    updateFilters(filters) {
      if (filters.clientType) {
        this.filters.clientType = clone(filters.clientType);
      }

      if (filters.buildings) {
        this.filters.buildings = clone(filters.buildings);
      }

      if (filters.contractorStatus) {
        this.filters.contractorStatus = clone(filters.contractorStatus);
      }

      if (filters.clientStatus) {
        this.filters.clientStatus = clone(filters.clientStatus);
      }

      if (filters.rentalPeriod) {
        const [after, before] = filters.rentalPeriod.split(' - ');
        this.filters.rentalPeriodBefore = before;
        this.filters.rentalPeriodAfter = after;
      } else {
        this.filters.rentalPeriodBefore = undefined;
        this.filters.rentalPeriodAfter = undefined;
      }
    },

    showAgreement(item) {
      this.$router.push({
        name: AGREEMENTS_DETAILED,
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.agreements {
  .v-form {
    display: flex;
    align-items: flex-end;

    & > span {
      flex: 1 1 auto;
    }
  }

  .v-data-table {
    td,
    th {
      max-width: 1px;
    }
  }
}
</style>
